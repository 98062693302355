import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase/firebaseInit'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    redirect: '/'
    
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/DashBoard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'About',
      requiresAuth: true
    }
  },
  {
    path: '/auth/login',
    name: 'Authlogin',
    component: () => import( '../views/Auth/LogIn.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/auth/logout',
    name: 'Authlogout',
    component: () => import( '../views/Auth/LogOut.vue'),
    meta: {
      title: 'Logout',
      requiresAuth: true
    }
  },
  {
    path: '/mysite',
    name: 'MySite',
    component: () => import( '../views/Site/MySite.vue'),
    meta: {
      title: 'My Site',
      requiresAuth: true,
      mode: 'editor',
    }
  },
  {
    path: '/site/:siteid',
    alias: '/site',
    name: 'DomainService',
    component: () => import( '../views/Site/DomainService.vue'),
    meta: {
      title: 'Domain Service',
      requiresAuth: true,
      mode: 'editor',
    }
  },
  {
    path: '/site/manager',
    name: 'SiteManager',
    component: () => import( '../views/Site/Manager.vue'),
    meta: {
      title: 'Site Manager',
      requiresAuth: true,
      mode: 'editor',
    }
  },
  {
    path: '/site/pages',
    name: 'SitePages',
    component: () => import( '../views/Site/Pages.vue'),
    meta: {
      title: 'Pages Editors',
      requiresAuth: true,
      mode: 'editor',
    }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, form, next) => {
  // const browserHeaderTitle = to.name
  document.title = to.meta.title ? to.meta.title : 'Web-C IPUMON';
  var currentUrl = window.location.pathname;
  if (to.path === currentUrl && auth.currentUser) {
    next()
    return;
  }
  
  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next("/auth/login")
    return;
  }

  next();
})

export default router