import { createStore } from 'vuex'
import  { auth }  from '../firebase/firebaseInit'
import router  from '../router'
import { doc, getDoc} from 'firebase/firestore'
import { collection, getDocs, query, where, limit, updateDoc } from 'firebase/firestore'
import db from '../firebase/firebaseInit.js'
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'

const store = createStore({
  state: {
    // user
    user: null,
    authIsReady: false,
    profileLoaded: null,
    // site mode
  },
  getters: {
  },
  mutations: {
    // user
    setUser(state, payload) {
      state.user = payload
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    },  
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      // state.profileEmail = doc.data().email;
      state.Sites = doc.data().Sites;
      state.l = "ok"
      
      console.log("loaded "+this.state.Sites+this.state.l)
    },
    profileLoaded(state) {
      state.profileLoaded = true;
    },
  },
  actions: {
    async login(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete login')
      }
    },
    async logout(context) {
      await signOut(auth)
      context.commit('setUser', null)
      window.location = "/"
    },
    fetchUser ({ commit }) {
      var currentUrl = window.location.pathname;
      auth.onAuthStateChanged(async user => {
        if (user === null){
          return
        } else {
          commit('setUser', user)
          const useruid = user.uid
          const docRef = doc(db, "Users", "WAgrujAUhaN7zBpxKxmuVbBYwUF3");
          const docSnap = await getDoc(docRef);
          commit("setProfileInfo", docSnap);
          // commit("setProfileInitials");
          commit("profileLoaded")
          
          if(router.isReady() && router.currentRoute.value.path === '/auth/login') {
            router.push(currentUrl)
          }
        }
      })
    },

  },
  modules: {
  }
})

const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setAuthIsReady', true)
  store.commit('setUser', user)
  unsub()
})

// export the store
export default store