<style>
body {font-family: Arial, Helvetica, sans-serif;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.navbar {
  width: 100%;
  background-color: #555;
  overflow: auto;
}

.navbar a {
  float: left;
  padding: 12px;
  color: white;
  text-decoration: none;
  font-size: 17px;
}

.nav a.router-link-active {
  background-color: #000;
  color: white;
}
li select {
  background-color: #555;
  border-color: #fff;
  width: 500%;
  padding: 2% 2%;
  margin: 10% 0;
  box-sizing: border-box;
}
@media screen and (max-width: 600px) {
  .navbar a {
    float: none;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .grid-container {
  grid-template-columns: auto;
  }
}
@media screen and (min-width: 900px) {
  .grid-container {
  grid-template-columns: auto auto;
}
}
@media screen and (min-width: 1401px) {
  .grid-container {
  grid-template-columns: auto auto auto;
}
}
/* @media screen and (min-width: 1900px) {
  .grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 50px;
  padding: 20px;
}
} */
.grid-container {
  display: grid;
  column-gap: 50px;
  padding: 20px;
}
.grid-container > div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 400px;
  margin: auto;
  text-align: center;
  font-family: arial;
  margin: 5%;
  padding: 5%;
}

.price {
  color: grey;
  font-size: 22px;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card button:hover {
  opacity: 0.7;
}
.main input[type=text], input[type=number], input[type=submit], textarea, select {
  width: 100%;
  padding: 2% 2%;
  margin: 2% 0;
  box-sizing: border-box;
}
.main{
  margin: 5%;
}
</style>
<template>
  <nav class="navbar navbar-inverse">
    <div class="container-fluid">
      <div class="navbar-header">
        <router-link class="navbar-brand" to="#">Web-Collective</router-link>
      </div>
      <ul v-if="$store.state.user" class="nav navbar-nav">
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/mysite">My Site</router-link></li>
      </ul>
      <ul v-if="$store.state.user" class="nav navbar-nav navbar-right">
        <li><router-link to="#"><span class="glyphicon glyphicon-user"></span> {{ this.$store.state.user.email }}</router-link></li>
        <li><router-link to="/auth/logout"><span class="glyphicon glyphicon-log-in"></span>  Logout</router-link></li>
      </ul>
      <ul v-if="$route.meta.mode == 'editor'" class="nav navbar-nav navbar-bottom">
        <li><router-link style="background-color: none;" to="#MySite" class="glyphicon glyphicon-home"> MySite</router-link></li>
        <li><select v-model="editor_mode">
          <option>No Site found</option>
                <option v-for="Site in this.$store.state.Sites" :key="Site.id">
                    {{ Site }}
                </option>
            </select>
        </li>
      </ul>
    </div>
  </nav>
  <div>
    <router-view/>
  </div>
  <div>
    <!-- {{ $route }} -->
  </div>
</template>
<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    onBeforeMount(() => {
      store.dispatch('fetchUser')
    })
  },
}
</script>

